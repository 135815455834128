<template>
  <div
    class="col-span-5 md:col-span-4 flex flex-col justify-center w-full items-center"
  >
    <div class="p-6 rounded-full overflow-hidden bg-primary">
      <img :src="icResetPin" alt="Reset pin" />
    </div>
    <div class="font-bold text-lg text-black mt-3">Forgot Balance PIN</div>
    <div class="w-full md:w-1/2 lg:w-1/3 space-y-6 mt-9" v-if="isProcess">
      <div id="group">
        <div class="text-sm font-semibold text-black whitespace-nowrap mb-1">
          New PIN
        </div>
        <div class="flex relative justify-between h-9">
          <div
            class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
          >
            <img :src="icLock" class="w-4" alt="lihat" />
          </div>
          <input
            :type="eye.new ? 'password' : 'text'"
            v-model="pin.new"
            class="w-full h-full outline-none pl-5 pr-12 text-xl font-semibold text-black border-b-4 border-primary"
            autocomplete="new-password"
            @keypress="isNumber($event)"
            maxlength="6"
          />
          <img
            class="absolute right-3 top-2"
            @click="showNew"
            :src="eye.new ? icEyeOpen : icEyeClose"
            alt=""
          />
        </div>
      </div>
      <div id="group">
        <div class="text-sm font-semibold text-black whitespace-nowrap mb-1">
          Confirm New PIN
        </div>
        <div class="flex relative justify-between h-9">
          <div
            class="bg-primary rounded-l-lg flex w-11 items-center justify-center text-center cursor-pointer"
          >
            <img :src="icLock" class="w-4" alt="lihat" />
          </div>
          <input
            :type="eye.confirm ? 'password' : 'text'"
            v-model="pin.confirm"
            class="w-full h-full outline-none pl-5 pr-12 text-xl font-semibold text-black border-b-4 border-primary"
            autocomplete="new-password"
            @keypress="isNumber($event)"
            maxlength="6"
          />
          <img
            class="absolute right-3 top-2"
            @click="showConfirm"
            :src="eye.confirm ? icEyeOpen : icEyeClose"
            alt=""
          />
        </div>
        <div class="flex justify-between mb-1 text-sm font-bold">
          <span>NB: PIN only numeric</span>
        </div>
      </div>
      <div class="flex justify-between">
        <button
          type="button"
          @click="$router.push({ name: 'ResetPin' })"
          class="flex items-center space-x-3 mt-7 bg-f5 rounded-10 text-base text-black font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
        >
          Cancel
        </button>
        <button
          @click="updatePin"
          :disabled="loading"
          class="flex items-center space-x-3 bg-primary py-2 px-8 mt-7 text-white font-semibold text-base rounded-10 cursor-pointer hover:shadow-lg transition-all duration-300"
        >
          <img v-if="!loading" :src="icSave" alt="" />
          <svg
            v-else
            class="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <div class="">Save</div>
        </button>
      </div>
    </div>
    <div class="w-full md:w-1/2 lg:w-1/3 space-y-6 mt-9" v-else>
      <div
        class="border rounded-3xl shadow-xl pt-4 pb-6 w-full text-center mx-auto grid justify-item-center"
      >
        <div class="text-center text-xl text-gray-800 font-bold">
          Verify OTP Code
        </div>
        <div class="text-center text-gray-500 font-medium mt-2">
          We have sent a verification code to {{ $store.state.user.telepon }}.
        </div>
        <v-otp-input
          class="flex justify-between w-full mx-auto mt-6 px-6"
          :num-inputs="6"
          separator=""
          input-type="number"
          input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
          @on-change="otp = $event"
          @on-complete="otp = $event"
        />
      </div>
      <button
        @click="verifyOtp"
        :disabled="isInvalidOtp"
        class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-12 block mx-auto rounded-lg mt-8"
      >
        Verify
      </button>
      <div class="text-center text-sm mt-8">
        <span class="font-medium">Didn't receive an OTP ?</span>
        <span @click="resendOtp" class="cursor-pointer font-bold">
          Resend OTP</span
        >
        <span v-show="sendOtpInCooldown">
          in
          <span class="text-red-500 font-semibold"
            >{{ otpTimerMinutes }} : {{ otpTimerSeconds }}</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forgot",
  data: () => ({
    icResetPin: require("@/assets/icons/ic-reset-pin.svg"),
    icLock: require("@/assets/icons/ic-lock.svg"),
    icSave: require("@/assets/icons/ic-save.svg"),
    icEyeOpen: require("@/assets/icons/ic-eye-open.svg"),
    icEyeClose: require("@/assets/icons/ic-eye-close.svg"),
    loading: false,
    isProcess: true,
    eye: {
      current: true,
      new: true,
      confirm: true,
    },
    pin: {
      current: null,
      new: null,
      confirm: null,
    },
    otp: "",
    otpTimer: 0,
  }),
  methods: {
    resetPin() {
      this.pin = {
        new: null,
        confirm: null,
      };
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setOtpTimer(second) {
      this.otpTimer = second;
      this.startOtpTimerCountdown();
    },
    startOtpTimerCountdown() {
      if (this.otpTimer > 0) {
        setTimeout(() => {
          this.otpTimer -= 1;
          this.startOtpTimerCountdown();
        }, 1000);
      }
    },
    async updatePin() {
      if (this.pin.new !== this.pin.confirm) {
        this.$toasted.global.error("Confirmation PIN not same");
        return;
      }
      this.$store.commit("setOverlayLoading", true);

      try {
        const otpResponse = await this.$http.post(
          `${this.$apiTripweWebV2}/users/forgot-pin/validate`,
          {
            new_pin: this.pin.new,
            confirm_new_pin: this.pin.confirm,
          }
        );
        if (otpResponse.data.status != 200 && otpResponse.data.status != 403) {
          throw otpResponse.data.message;
        }
        var curTime = this.$moment();
        var nextReq = this.$moment(otpResponse.data.data.next_request_at);
        var diff = nextReq.diff(curTime, 'seconds');
        if(otpResponse.data.status == 403){
          this.$toasted.global.error(otpResponse.data.message);
        }else{
          this.$toasted.global.success(otpResponse.data.message);
        }
        this.setOtpTimer(diff);
        this.isProcess = false;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async verifyOtp() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const updateRes = await this.$http.post(
          `${this.$apiTripweWebV2}/users/forgot-pin`,
          {
            new_pin: this.pin.new,
            confirm_new_pin: this.pin.confirm,
            otp: this.otp,
          }
        );

        if (updateRes.data.status != "200") {
          throw updateRes.data.message;
        }

        this.$toasted.global.success("Your PIN has been updated");
        setTimeout(() => {
          location.reload();
        }, 1500);
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async resendOtp() {
      if (this.sendOtpInCooldown) {
        this.$toasted.global.error(
          "Please wait a moment before sending new OTP"
        );
        return;
      }

      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.post(
          `${this.$apiTripweWebV2}/notifications/send-otp`,
          {
            phone: this.$store.state.user.telepon,
            scope: "FORGOT_PIN",
          }
        );

        if (response.data.status != 200 && response.data.status != 403) {
          throw response.data.message;
        }else{
          var curTime = this.$moment();
          var nextReq = this.$moment(response.data.data.next_request_at);
          var diff = nextReq.diff(curTime, 'seconds');
          if(response.data.status == 403){
            this.$toasted.global.error(response.data.message);
          }else{
            this.$toasted.global.success(response.data.message);
          }
          this.setOtpTimer(diff);
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    showCurrent() {
      this.eye.current = !this.eye.current;
    },
    showNew() {
      this.eye.new = !this.eye.new;
    },
    showConfirm() {
      this.eye.confirm = !this.eye.confirm;
    },
  },
  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Balance", routeName: "TripwePay" },
      { text: "Setting", routeName: "Settings" },
    ]);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isInvalidOtp() {
      return this.otp.toString().length != 6;
    },
    otpTimerMinutes() {
      const val = Math.floor(this.otpTimer / 60);
      return val < 10 ? `0${val}` : val;
    },
    otpTimerSeconds() {
      const val = this.otpTimer - this.otpTimerMinutes * 60;
      return val < 10 ? `0${val}` : val;
    },
    sendOtpInCooldown() {
      return this.otpTimer > 0;
    },
  },
};
</script>

<style scoped>
input[type="password"] {
  -webkit-text-security: square;
}
</style>
